<template>
  <div class="error-wrapper">
    <navbar>
      <home-links
        v-if="!isMobile"
        :materials-url="materialsUrl"
        :blog-url="blogUrl"
        :login-url="loginUrl"
      />

      <mobile-menu
        v-else
        :is-opened="showMobileMenu"
        :materials-url="materialsUrl"
        :blog-url="blogUrl"
        :login-url="loginUrl"
        @toggle="showMobileMenu = !showMobileMenu"
      />
    </navbar>

    <div class="msg-box">
      <div class="main">
        <h2 class="title">404</h2>
        <p class="message">Página não encontrada</p>
      </div>

      <div class="sub">
        <span class="text">
          Desculpa, não conseguimos encontrar a página que você está procurando.
        </span>
        <submit-button class="btn" @click="redirect">
          Voltar para home
        </submit-button>
      </div>
    </div>
  </div>
</template>

<script>
import SubmitButton from '@components/form/SubmitButton'
import HomeLinks from '@components/layout/HomeLinks'
import Navbar from '@components/layout/Navbar'
import MobileMenu from '@components/layout/MobileMenu'

import name from '@router/names'

import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'PageNotFound',

  components: {
    SubmitButton,
    MobileMenu,
    HomeLinks,
    Navbar,
  },

  mixins: [ MediaQuery ],

  inject: [ 'materialsUrl', 'blogUrl', 'homeUrl', 'loginUrl' ],

  data: () => ({ showMobileMenu: false }),

  methods: {
    redirect () {
      window.location = this.homeUrl
    }
  },

  async beforeRouteEnter (to, from, next) {
    if (!from.name) return next({ name: name.REGISTER })
    next()
  }
}
</script>

<style lang="scss">
.error-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  background-color: #FFF;
  background-image: url('~@assets/404.svg');
  background-repeat: no-repeat;
  background-position: center 508px;

  @include xs-mobile {
    background-size: 100% auto;
  }

  & > .navbar {
    padding: 22px 24px;

    @include responsive (tablet, desktop) {
      justify-content: flex-start;
      padding: 24px 80px;
    }

    & > .logo {
      display: flex;
      align-items: center;
      margin-right: 109px;
    }
  }

  & > .msg-box {
    margin: 76px auto auto;

    & > .main {
      display: flex;
      flex-direction: column;
      align-items: center;

      font-family: $font-family-roboto-bold;

      & > .title {
        font-size: 64px;
        line-height: 57px;
        color: rgba($base-text-color, 0.9);

        @include xs-mobile {
          font-size: 54px;
          line-height: 57px;
        }
      }

      & > .message {
        font-size: 28px;
        line-height: 57px;
        margin-top: 24px;
        color: rgba($base-text-color, 0.9);

        @include xs-mobile {
          font-size: 22px;
          line-height: 31px;
        }
      }
    }

    & > .sub {
      display: flex;
      flex-direction: column;
      align-items: center;

      font-family: $font-family-nunito-regular;
      max-width: 383px;
      margin-top: 24px;

      @include xs-mobile { margin-top: 16px; }

      & > .text {
        font-size: 20px;
        line-height: 32px;
        color: rgba($base-text-color, 0.7);
        text-align: center;

        @include xs-mobile {
          font-size: 16px;
          line-height: 26px;
          max-width: 290px;
        }
      }

      & > .submit-button {
        width: 100%;
        max-width: 278px;
        margin-top: 32px;
      }
    }
  }
}
</style>
