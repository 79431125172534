<template>
  <div :class="['app', { '-ios': isIphoneOS }]" id="app">
    <router-view v-bind="$props" class="view"/>
  </div>
</template>

<script>
import createAxios from '@modules/request/createAxios'
import { MediaQueryRoot } from '@convenia/mixins'

export default {
  mixins: [ MediaQueryRoot ],

  // putting the env as prop as posibility to use this app externally
  props: {
    cookieDomain: String,
    appUrl: String,
    homeUrl: String,
    loginUrl: String,
    materialsUrl: String,
    blogUrl: String,
    api: String,
  },

  data: () => ({ isIphoneOS: false }),

  provide () {
    return {
      request: createAxios(this.api),
      homeUrl: this.homeUrl,
      cookieDomain: this.cookieDomain,
      loginUrl: this.loginUrl,
      appUrl: this.appUrl,
      blogUrl: this.blogUrl,
      materialsUrl: this.materialsUrl
    }
  },

  created () {
    this.isIphoneOS = /iPad|iPhone|iPod/g.test(navigator.platform)
    || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  }
}
</script>

<style lang="scss">
.app {
  width: 100%;
  overflow-x: hidden;

  /* Not sure if necessary so I'll just comment it out
  & > .view {
    @include responsive (xs-mobile, mobile) {
      padding: 0 15px 80px 15px;
    }
  }*/

  &.-ios {
    @extend %iphone-zoom-fix;
  }
}
</style>
