<template>
  <transition
    name="fade-slide"
    :style="{ '--direction': styleDirection }"
    :mode="mode"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  props: {
    direction: {
      type: String,
      default: 'right'
    },
    mode: {
      type: String,
      default: 'out-in'
    }
  },

  computed: {
    styleDirection () {
      if (this.direction === 'left') return '-10px'
      if (this.direction === 'right') return '10px'
      return '0'
    }
  },

  mounted () {
    this.setParentDirection(this.$el, this.styleDirection)
  },

  watch: {
    direction () {
      this.setParentDirection(this.$el, this.styleDirection)
    }
  },

  methods: {
    setParentDirection (el, direction) {
      if (el && el.parentElement) {
        el.parentElement.style
          .setProperty('--fade-slide-direction', direction)
      }
    }
  }
}
</script>

<style lang="scss">
.fade-slide-enter-active, .fade-slide-leave-active {
  transition: opacity .3s, height, transform .3s;
}
.fade-slide-enter, .fade-slide-leave-to {
  opacity: 0;
  transform: translateX(var(--fade-slide-direction))
}
</style>
