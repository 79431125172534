import Cookie from 'js-cookie'

export default () => {
  const location = window.location.href
  const fullURI = location.replace(/(&|\?)?hubspot=(\w+)/g, '')
  const hubspotCookie = (location.match(/.*hubspot=(\w+)/i) || [])[1]
    || Cookie.get('hubspotutk') || ''

  return { url: fullURI, cookie: hubspotCookie }
}
