<template>
  <div :class="[ 'view-register', { '-full-content': !!user }]">
    <main class="content" :class="classes">
      <navbar class="navbar" :home-url="homeUrl" :is-mobile="isMobile">
        <login-link v-if="!isMobile" :login-url="loginUrl"/>
      </navbar>

      <fade-slide>
        <!-- Main Container -->
        <registration-container
          v-if="!user"
          class="new-register-form"
          @error="prepareScreen.isOpened = false"
          @prepare="prepareScreen.isOpened = true"
          @success="() => redirect()"
        />

      </fade-slide>

      <copyright v-if="!isMobile" />

      <div v-if="isMobile" class="login-link-wrapper">
        <login-link class="login-link" :login-url="loginUrl"/>
      </div>
    </main>

    <aside-info
      v-if="!isMobile"
      title="O teste grátis inclui:"
      class="aside-info"
    >
      <ul class="features">
        <li v-for="feature in $options.features" :key="feature" class="feature">
          <img
            class="done-check"
            :src="require('@assets/done-check-white.svg')"
            aria-hidden="true"
          />

          <span class="text">{{ feature }}</span>
        </li>
      </ul>
    </aside-info>

    <opacity-transition>
      <prepare-screen :is-opened="prepareScreen.isOpened" />
    </opacity-transition>
  </div>
</template>
<script>
import { MediaQuery } from '@convenia/mixins'
import AsideInfo from '@components/layout/AsideInfo'
import Copyright from '@components/layout/Copyright'
import Navbar from '@components/layout/Navbar'
import LoginLink from '@components/layout/LoginLink'
import RegistrationContainer from '@containers/NewRegistration'
import FadeSlide from '@components/transitions/FadeSlide.vue'
import PrepareScreen from '@components/layout/PrepareScreen.vue'
import OpacityTransition from '../components/transitions/Opacity.vue'

export default {
  name: 'RegisterTrial',

  data: () => ({
    user: null,
    prepareScreen: {
      isOpened: false
    }
  }),

  features: [
    '14 dias de uso gratuito',
    'Não é necessário dados bancários',
    'Todas as funcionalidades'
  ],

  mixins: [ MediaQuery ],

  components: {
    AsideInfo,
    Navbar,
    LoginLink,
    RegistrationContainer,
    FadeSlide,
    Copyright,
    PrepareScreen,
    OpacityTransition
  },

  props: {
    homeUrl: String,
    loginUrl: String,
    appUrl: String
  },

  computed: {
    classes () {
      return this.prepareScreen.isOpened ? 'submiting' : null
    }
  },

  methods: {
    async redirect () {
      window.location = this.appUrl
    }
  }
}
</script>

<style lang="scss">
.view-register {
  display: grid;
  grid-template-columns: 1fr;

  width: 100%;
  overflow-x: hidden;
  transition: width .5s, transform .5s;
  min-height: 100%;
  position: relative;

  @include responsive (tablet, desktop) {
    grid-template-columns: 1fr 360px;
    height: 100%;
    overflow-x: hidden;
    transform: translateX(0);
    transition: transform .5s, width .5s;

    &.-full-content {
      width: calc(100% + #{$aside-width});
    }
  }

  & > .aside-info {
    & > .features {
      margin-top: 24px;
      flex-basis: $aside-width;
    }

    & > .features > .feature {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      -webkit-font-smoothing: antialiased
    }

    & > .features > .feature > .done-check {
      $size: 26px;
      width: $size;
      height: $size;
      margin-right: 5px;
      opacity: 0.6;
    }

    & > .features > .feature > .text {
      -webkit-font-smoothing: antialiased;
      font-family: $font-family-nunito-regular;
      color: #ffffff;
      letter-spacing: 0;
      line-height: 22px;
      opacity: 0.9;
    }
  }

  & > .content {
    display: flex;
    flex-direction: column;
    background-image: url('~@assets/bg-trial.png');
    background-repeat: no-repeat;
    background-size: cover;

    &.submiting {
      overflow: hidden;
    }

    & > .new-register-form {
      margin-top: 66px;
      margin-bottom: 40px;

      @include responsive (xs-mobile, mobile) {
        margin-top: 40px;
      }

      .fields {
        padding-bottom: 20px;
        position: relative;

        .input-password .strength-meter {
          bottom: 0px;
          position: absolute;
          width: 100%;
        }
      }
    }

    & > .login-link-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      margin-top: auto;
      margin-bottom: 30px;
    }
  }
}
</style>
