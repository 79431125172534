<template functional>
  <aside class="aside-info">
    <slot name="before" />

    <video autoplay muted loop class="pattern-video">
      <source :src="require('@assets/pattern.mp4')" type="video/mp4">
    </video>

    <img
      class="calendar img"
      aria-hidden="true"
      :src="require('@assets/icon_calendar.svg')"
    />

    <div class="title">
      <h3 class="text">
        {{ props.title }}
      </h3>

      <img
        class="underline"
        aria-hidden="true"
        :src="require('@assets/title underline yellow.svg')"
      />
    </div>

    <slot />
  </aside>
</template>

<script>

export default {
  props: {
    title: String
  }
}
</script>

<style lang="scss">
.aside-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $aside-padding;
  background-color: #A308FC;
  position: relative;
  overflow: hidden;
  width: 360px;

  & > .calendar {
    width: 160px;
    height: 160px;
    margin: 0 auto;
  }

  & > .img, & > .title {
    z-index: 10;
  }

  & > .title {
    margin-top: 40px;

    & > .text {
      color: #FFFFFF;
      font-family: "Roboto Slab Bold";
      font-size: 28px;
      letter-spacing: 0;
      //line-height: 42px;
      -webkit-font-smoothing: antialiased
    }

    & > .underline {
      margin-top: 7px;
    }
  }

  & > .pattern-video {
    height: 120%;
    left: -1692px;
    position: absolute;
    top: -176px;
  }

}
</style>
