/* eslint-disable no-unused-vars */
import { SERVER_EMAIL_ERROR } from '@content/consts'
import getGTMVars from '@modules/getGtmVars'
import jwt from '@modules/recaptcha/jwt'

const parseName = (value) => {
  if (!value) return ''

  const preps = [ 'da', 'das', 'de', 'do', 'dos' ]

  const formatter = txt => preps.indexOf(txt) > -1
    ? txt : txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()

  return value.toLowerCase().split(' ').map(formatter).join(' ')
}

const createPayload = ({ fullName, email, phone }, captcha) => {
  const [ name, ...otherNames ] = parseName(fullName).split(' ')
  const last_name = otherNames.join(' ')
  return { name, last_name, captcha, email, phone, step: 1, ...getGTMVars() }
}

const createPayloadNewTrial = ({ fullName, company_name, email, phone, password }, captcha) => {
  const [ name, ...otherNames ] = parseName(fullName).split(' ')
  const last_name = otherNames.join(' ')
  return { name, last_name, company_name, email, phone, password, captcha, ...getGTMVars() }
}

const checkValidEmailHubspot = (errors) => {
  return errors === 'INVALID_EMAIL' || errors === 'BLOCKED_EMAIL'
}

const checkEmailError = ({ email }) => {
  return (email || [])
    .includes(SERVER_EMAIL_ERROR)
}

export default async (request, formData) => {
  const recaptchaToken = await jwt()
  const payload = createPayloadNewTrial(formData, recaptchaToken)

  const [ error, response ] = await request.post('/registrations', payload)
  const { errors, data, registration } = response || {}

  const emailInUse = data
    ? checkEmailError(data)
    : false

  const notValidEmailHubspot = errors
    ? checkValidEmailHubspot(errors)
    : false

  const emailError = error && registration
    ? checkEmailError(registration)
    : false

  return [ error, { registration, emailInUse, notValidEmailHubspot, emailError } ]
}
