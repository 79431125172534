import axios from 'axios'

const getData = (response, error) => {
  return [ error, response.data ]
}

const onRejected = error => {
  return error.response
    ? getData(error.response, error)
    : [ error, {} ]
}

export default baseURL => {
  const instance = axios.create({ baseURL })
  instance.interceptors.response.use(getData, onRejected)

  return instance
}
