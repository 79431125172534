<template>
  <div v-if="isOpened" class="prepare-screen">
    <div class="content">
      <img alt="Ícone de cadeado brilhando" class="image" src="@assets/Mobile/senha.png">

      <h1 class="title">Estamos preparando tudo...</h1>

      <p class="description">
        Aproveite o seu período teste de 14 dias para ver como a Convenia
        <br class="breaking-row"/>
        pode facilitar a sua rotina de RH.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrepareScreen',

  props: {
    isOpened: Boolean
  },
}
</script>

<style lang="scss" scoped>
  .prepare-screen {
    align-items: flex-start;
    background-image: url('../../assets/bg_final_step.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 80px 16px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 11;

    .content {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 8px;
      z-index: 13;
      text-align: center;

      > .image {
        box-shadow: 0 25px 100px 0 rgba(24,50,115,0.06);
        display: block;
        height: 100%;
        margin-bottom: 16px;
        max-width: 160px;
        width: 100%;
      }

      > .title {
        font-family: $font-family-roboto-bold;
        color: rgba($base-text-color, .9);
        font-size: 28px;
        line-height: 42px;
        letter-spacing: 0;
      }

      > .description {
        font-family: $font-family-nunito-regular;
        color: rgba($base-text-color, .8);
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0;

        > .breaking-row {
          @media (max-width: 455px) {
            display: none;
          }
        }
      }
    }
  }
</style>
