export function injectScript (src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.async = true
    script.src = src
    script.addEventListener('load', resolve)
    script.addEventListener('error', error => reject(error))
    script.addEventListener('abort', error => reject(error))
    document.head.appendChild(script)
  })
}
