<template functional>
  <span class="login-link">
    <span class="question"> Já possui uma conta? </span>

    <a class="redirect" :href="props.loginUrl">
      ENTRAR
    </a>
  </span>
</template>

<script>
export default {
  props: {
    loginUrl: String
  }
}
</script>

<style lang="scss">
.login-link {
  display: flex;
  align-items: center;
  justify-content: center;

  & > .question, & > .redirect {
    font-family: $font-family-nunito-regular;
    letter-spacing: 0;
    line-height: 18px;
    color: rgba(18,30,72,0.7);

    transition: color 200ms;
  }

  & > .question {
    font-size: 16px;
    margin-right: 5px;
  }

  & > .redirect {
    padding-top: 2px;
    font-size: 13px;
    font-family: $font-family-nunito-bold;

    &:hover { color: rgba(18,30,72,0.9); }
  }
}
</style>
