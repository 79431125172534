<template functional>
  <nav :class="[ 'navbar', { '-mobile': props.isMobile } ]">
    <a class="logo" :href="props.homeUrl" >
      <img class="img" alt="logo" :src="require('@assets/logo 1.svg')" />
    </a>

    <slot />
  </nav>
</template>

<script>
export default {
  props: {
    isMobile: Boolean,
    homeUrl: String
  }
}
</script>

<style lang="scss">
.navbar {
  display: flex;
  justify-content: space-between;
  padding: 32px;
  padding: {
    top: 32px;
    right: 42px;
    bottom: 32px;
    left: 32px;
  }

  &.-mobile {
    padding: 22px;
  }

  & > .logo > .img {
    width: 101.04px;
  }
}
</style>
