<template>
  <button
    :class="classes"
    :disabled="loading || disabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div v-if="!loading" class="content">
      <!-- @slot Button content -->
      <slot />
    </div>

    <img v-else class="loading" :src="require('@assets/loader.gif')" />
  </button>
</template>

<script>
export default {
  name: 'SubmitButton',

  props: {
    /**
     * Expand its width to the parent Element
     */
    expand: Boolean,

    /**
     * Loading state
     */
    loading: Boolean,

    /**
     * Disabled
     */
    disabled: Boolean,

    /**
     * Removes primary styling from button and makes it look like a link
     */
    ghost: Boolean,

    /**
     * Makes the button greyish
     */
    grey: Boolean,
  },

  computed: {
    classes () {
      return [ 'submit-button', {
        '-loading': this.loading,
        '-disabled': this.disabled,
        '-ghost': this.ghost,
        '-grey': this.grey
      } ]
    }
  }
}
</script>

<style lang="scss">
.submit-button {
  position: relative;

  border: 0;
  padding: 20px;
  border-radius: 100px;
  height: 50px;
  background: #FF4BB7;
  outline: none;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: $font-family-nunito-bold;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: bold;
  color: #FFFFFF;

  transform-style: preserve-3d;
  transition: background 300ms ease, box-shadow 300ms ease;

  &.-ghost {
    background: transparent;
    color: rgba($base-text-color, 0.7);

    &::before, &::after { content: unset; }
  }

  &.-grey {
    background: rgba($base-text-color, .05);
    color: rgba($base-text-color, 0.7);
    transition: color ease 100ms;

    &:hover { color: rgba($base-text-color, .9); }

    &::before, &::after { content: unset; }
  }

  &::before {
    z-index: 10;
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: calc(50% + 9px);
    width: 93%;
    height: 75%;
    border-radius: inherit;
    filter: blur(7px);
    opacity: 0.5;
    transform: translateX(-50%) translateY(-50%) translateZ(-1px);
    transition: filter .3s, opacity .3s;
    flex: 1;
    background: #FF4BB7;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    left: 0;
    top: 0;
    background: #fff;
    opacity: 0;
    transition: opacity .3s;
    flex: 1;
    z-index: 30;
  }

  &:hover {
    &::before { opacity: 0.3; filter: blur(14px); }
    &::after { opacity: 0.2; }
  }

  & > .content, & > .loading {
    z-index: 20;
  }

  & > .loading {
    width: 30px;
    height: 34px;
    opacity: 0.7;
  }

  &.-loading, &.-disabled {
    cursor: default;
  }
}
</style>
