<template>
  <div :class="[ 'view-register', { '-full-content': !!user }]">
    <main class="content">
      <!-- Main Container -->
      <registration-container
        v-if="!user"
        class="new-register-form"
        @success="redirect"
      />
    </main>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'
import RegistrationContainer from '@containers/NewRegistration'

export default {
  name: 'Embed',

  data: () => ({
    user: null
  }),

  features: [
    '14 dias de uso gratuito',
    'Não é necessário dados bancários',
    'Todas as funcionalidades'
  ],

  mixins: [ MediaQuery ],

  components: {
    RegistrationContainer,
  },

  props: {
    appUrl: String
  },

  methods: {
    async redirect () {
      window.open(this.appUrl, '_blank').focus();
    }
  },

  created () {
    document.body.classList.add('embed-page');
  },
}
</script>

<style lang="scss">
.embed-page {
  background: transparent;

  > .app > .view-register {
    min-height: 100%;
    position: relative;

    @include responsive (tablet, desktop) {
      grid-template-columns: 1fr;
      height: 100%;
      overflow-x: hidden;
      transform: translateX(0);
      transition: transform .5s, width .5s;
    }

    & > .content {
      background-image: none;
      display: flex;
      flex-direction: column;

      &.submiting {
        overflow: hidden;
      }
    }
  }
}
</style>
