<template>
  <div class="home-links">
    <div class="menu">
      <a v-for="item in menuItems" :key="item.label" class="link" :href="item.link">
        {{ item.label }}
      </a>
    </div>

    <div class="actions">
      <submit-button class="action -login" ghost @click="redirectLogin">
        <img class="icon" :src="require('@assets/icon-login.svg')" />
        <span class="text">Entrar</span>
      </submit-button>

      <submit-button type="button" class="action" @click="redirectRegister">
        Testar grátis
      </submit-button>
    </div>
  </div>
</template>

<script>
import SubmitButton from '@components/form/SubmitButton'

export default {
  name: 'HomeLinks',

  components: { SubmitButton },

  props: {
    materialsUrl: {
      type: String,
      required: true
    },

    blogUrl: {
      type: String,
      required: true
    },

    loginUrl: {
      type: String,
      required: true
    }
  },

  computed: {
    menuItems () {
      const items = [
        /* Commented out for now
        {
          label: 'Plataforma',
          link: ''
        },
        {
          label: 'Planos',
          link: ''
        },
        */
        {
          label: 'Blog',
          link: this.blogUrl
        },
        {
          label: 'Materiais',
          link: this.materialsUrl
        }
      ]

      return items
    }
  },

  methods: {
    redirectLogin () {
      window.location = this.loginUrl
    },

    redirectRegister () {
      this.$router.push({ name: 'Cadastro' })
    }
  }
}
</script>

<style lang="scss">

.home-links {
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-grow: 1;

  & > .menu {
    display: flex;
    align-items: flex-start;

    & > .link {
      font-family: $font-family-nunito-bold;
      font-size: 13px;
      line-height: 18px;
      color: rgba($base-text-color, 0.7);
      text-transform: uppercase;
      transition: color 200ms ease;

      &:hover { color: rgba($base-text-color, 0.9); }
      &:not(:last-child) { margin-right: 40px; }
    }
  }

  & > .actions {
    display: flex;
    align-items: center;

    & > .action {
      height: 40px;
      width: 160px;;

      &.-login {
        padding: 20px 0;

        &:hover {
          background: rgba($base-text-color, .05);
          & > .content { color: rgba($base-text-color, 0.9); }
        }

        & > .content {
          position: relative;
          display: flex;
          align-items: center;

          transition: color 300ms ease;
          width: 100%;

          & > .text {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }

          & > .icon { margin-left: 8px; }
        }
      }

      &:not(:last-child) { margin-right: 16px; }
    }
  }
}
</style>
