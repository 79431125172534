import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

const attrs = {
  appUrl: process.env.VUE_APP_APP_URL,
  cookieDomain: process.env.VUE_APP_COOKIE_DOMAIN,
  homeUrl: process.env.VUE_APP_HOME_URL,
  api: process.env.VUE_APP_CORE_API,
  loginUrl: process.env.VUE_APP_LOGIN_URL,
  blogUrl: process.env.VUE_APP_BLOG_URL,
  materialsUrl: process.env.VUE_APP_MATERIALS_URL
}

new Vue({
  router,
  render: h => h(App, { attrs })
}).$mount('#app')
