import Vue from 'vue'
import VueRouter from 'vue-router'
import name from './names'

Vue.use(VueRouter)

const routes = [
  {
    path: '/cadastro',
    name: name.REGISTER,
    component: () => import('@views/NewRegistration')
  },
  {
    path: '/passos/:id',
    name: name.STEPS,
    component: () => import('@views/Steps'),
  },
  {
    path: '/erro',
    name: name.ERROR,
    component: () => import('@views/Error')
  },
  {
    path: '/embed',
    name: name.EMEBED,
    component: () => import('@views/Embed')
  },
  {
    path: '/*',
    redirect: { name: name.REGISTER }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
