<template functional>
  <div :class="[ 'form-message', { '-positive': props.positive } ]">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    positive: Boolean
  }
}
</script>

<style lang="scss">
.form-message {
  padding: 16px 55px;
  border-radius: 5px;
  background-color: $negative-color;
  text-align: center;

  &.-positive {
    background-color: $success-color;
  }

  color: #FFFFFF;
  font-family: $font-family-nunito-regular;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
}
</style>
