<template>
  <div :class="[ 'mobile-menu', { '-closed': !isOpened } ]">
    <div :class="['trigger', { '-revert': !isOpened }]">
      <submit-button
        ghost
        type="button"
        class="btn-toggle"
        @click="$emit('toggle')"
      >
        <img
          class="icon"
          :src="require(isOpened ? '@assets/icon-close.svg' : '@assets/icon-menu.svg')"
        />
      </submit-button>
    </div>

    <div class="content">
      <div class="menu">
        <a v-for="item in menuItems" :key="item.label" :href="item.link" class="link">
          {{ item.label }}
        </a>
      </div>

      <div class="actions">
        <submit-button
          grey
          type="button"
          class="action -login"
          @click="redirectLogin"
        >
          <img class="icon" :src="require('@assets/icon-login.svg')" />
          <span class="text">Entrar</span>
        </submit-button>

        <submit-button
          type="button"
          class="action"
          @click="redirectRegister"
        >
          Testar grátis
        </submit-button>
      </div>
    </div>
  </div>
</template>

<script>
import SubmitButton from '@components/form/SubmitButton'

export default {
  name: 'MobileMenu',

  components: { SubmitButton },

  props: {
    isOpened: Boolean,
    materialsUrl: {
      type: String,
      required: true
    },

    blogUrl: {
      type: String,
      required: true
    },

    loginUrl: {
      type: String,
      required: true
    }
  },

  computed: {
    menuItems () {
      const items = [
        /* Commented out for now
        {
          label: 'Plataforma',
          link: ''
        },
        {
          label: 'Planos',
          link: ''
        },
        */
        {
          label: 'Blog',
          link: this.blogUrl
        },
        {
          label: 'Materiais',
          link: this.materialsUrl
        }
      ]

      return items
    }
  },

  methods: {
    redirectLogin () {
      window.location = this.loginUrl
    },

    redirectRegister () {
      this.$router.push({ name: 'Cadastro' })
    }
  }
}
</script>

<style lang="scss">

.mobile-menu {
  & > .trigger {
    position: relative;
    z-index: 30;

    &.-revert > .btn-toggle > .content > .icon {
      transform: scaleX(-1);
    }

    & > .btn-toggle { padding: 0; height: 24px; }
    & > .btn-toggle > .content { display: flex; }

    & > .btn-toggle > .content > .icon {
      width: 24px;
      height: 24px;
    }
  }

  &.-closed > .content,
  &.-closed::before {
    z-index: -999;
    opacity: 0;
    transition: opacity 300ms ease, z-index 0ms ease 350ms;
  }

  & > .content {
    display: flex;
    flex-direction: column;
    transition: opacity 300ms ease, z-index 0ms;

    position: absolute;
    left: 0px;
    bottom: 0px;

    z-index: 30;
    overflow: hidden;
    opacity: 1;

    width: 100vw;
    height: 100vh;

    max-height: calc(100vh - 76px);
    overflow: hidden;

    background: rgba(#FFF, .95);

    padding: 17px 16px 30px 16px;

    & > .menu {
      display: flex;
      flex-direction: column;
      margin-bottom: 34px;

      & > .link {
        padding: 22px 16px;
        font-size: 11px;
        font-family: $font-family-nunito-bold;
        text-transform: uppercase;
        color: rgba($base-text-color, .7);

        &:not(:last-child) {
          border-bottom: 1px solid rgba($base-text-color, .1);
        }
      }
    }

    & > .actions {
      display: flex;
      flex-direction: column;
      margin-bottom: 170px;

      & > .action {
        width: 100%;
        max-width: 624px;
        margin: 0 auto;

        &.-login > .content {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;

          & > .text {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }

          & > .icon { margin-right: auto; }
        }

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
  }
}
</style>
